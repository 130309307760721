import * as React from "react";
import Layout from "../components/layout";
import Container from "./../components/container";

const NotFoundPage = (pageContext) => {
  const locale = pageContext.pageContext.locale;
  const originalPath = pageContext.pageContext.originalPath;

  return (
    <Layout locale={locale} originalPath={originalPath}>
      <Container>
        <h1 style={{ textAlign: "center", margin: "120px 0" }}>404</h1>
      </Container>
    </Layout>
  );
};

export default NotFoundPage;
